import { useMemo, useState, useRef, type MouseEvent } from 'react'

import { Box, FormControlLabel, Checkbox, Typography } from '@mui/material'

import { Section, type SectionProps } from './components'
import { SpeakerSelection, UpgradePopup } from 'UI/Routes/quick-guidde/LeftPanel'

import { type AudioConfigsType, type BrandKitType, type SpeakerType } from 'app/types'

import { useNotification, useQuery, useServiceUsage } from 'hooks'
import { logToAnalytics, type OptionType } from 'modules'
import { useDispatch } from 'react-redux'
import { setAudioConfigs } from 'ducks'

type Props = {
    value: BrandKitType['voiceover']
} & SectionProps

type ConfigType = {
    languages: Array<OptionType>
    speakers: {
        [langCode: string]: Array<SpeakerType>
    }
}

const defaultConfig: ConfigType = {
    languages: [],
    speakers: {}
}

export const Voiceover = ({ popupProps, value, onEdit }: Props) => {
    const dispatch = useDispatch()
    const { showSuccessNotification } = useNotification()

    const { hasStudioVoices } = useServiceUsage()

    const upgradePopupRef = useRef<HTMLElement>(null)
    const [contactUsAnchorEl, setContactUsAnchorEl] = useState<HTMLElement | null>(null)

    const [upgradePopupSpeakers, setUpgradePopupSpeakers] = useState<Array<SpeakerType>>([])

    const $config = useQuery<AudioConfigsType>(
        '/c/v1/config/qg/audio',
        {
            method: 'GET'
        },
        {
            // this fields needs to force http call on qg edit page
            dedupingInterval: 0,
            onSuccess: data => dispatch(setAudioConfigs(data))
        }
    )

    const config = useMemo(() => {
        if (!$config.data) return defaultConfig

        const result = $config.data.speakers.reduce<ConfigType>((acc, speaker) => {
            // Create a new languages array and speakers object
            const newLanguages = [...acc.languages]
            const newSpeakers = { ...acc.speakers }

            // Add to languages array if it doesn't already exist
            if (!newLanguages.some(lang => lang.value === speaker.langCode)) {
                newLanguages.push({ label: speaker.langName, value: speaker.langCode })
            }

            // Initialize speakers array for this langCode if it doesn't exist
            if (!newSpeakers[speaker.langCode]) {
                newSpeakers[speaker.langCode] = []
            }

            // Add the speaker to the appropriate langCode array
            newSpeakers[speaker.langCode] = [...newSpeakers[speaker.langCode], speaker]

            // Return the new accumulated object
            return {
                languages: newLanguages,
                speakers: newSpeakers
            }
        }, defaultConfig)

        return {
            languages: result.languages.sort((a, b) => (a.label > b.label ? 1 : -1)),
            speakers: result.speakers
        }
    }, [$config.data])

    const handleSpeakerSelect = async (speaker: SpeakerType, voiceoverId: string) => {
        await onEdit({
            voiceover: {
                id: voiceoverId,
                langCode: speaker.langCode,
                speaker
            }
        })

        showSuccessNotification('Voiceover speaker changed')
    }

    const handleChangeCheckbox = (value: boolean) => {
        onEdit({
            generateVoiceoverForNewQG: value
        }).then(() => showSuccessNotification('Changes applied'))

        logToAnalytics('brandkitEdit', {
            actionType: value ? 'setActive' : 'setInactive',
            objectType: 'generateVoiceoverChk'
        })
    }

    const handleAudioPlay = (_speaker: SpeakerType) => {
        //Should we add log like in qg?
    }

    if (!value?.data) return null

    return (
        <Section
            popupProps={popupProps}
            title="Voiceover"
            subtitle="Set you brand's language and speaker to be used in your videos by default"
            content={
                <Box ml={2} ref={upgradePopupRef}>
                    {value?.data?.map(voiceover => {
                        if (!voiceover.speaker) {
                            console.error('[Voiceover section]: speaker doesn`t exist', {
                                voiceover
                            })
                            return null
                        }

                        const speakers = config.speakers?.[voiceover.langCode]

                        const handleSpeakerSelectionSave = (
                            e: MouseEvent<HTMLElement>,
                            speaker: SpeakerType
                        ) => {
                            const isStudioSpeakersLocked = speaker.isStudio && !hasStudioVoices

                            if (isStudioSpeakersLocked) {
                                setUpgradePopupSpeakers(speakers)
                                setContactUsAnchorEl(e.currentTarget)
                            } else {
                                handleSpeakerSelect(speaker, voiceover.id)
                            }
                        }

                        if (!speakers) return null

                        return (
                            <SpeakerSelection
                                key={voiceover.id}
                                currentSpeaker={voiceover.speaker}
                                onSave={handleSpeakerSelectionSave}
                                onAudioPlay={handleAudioPlay}
                                onEdit={popupProps.validateClick}
                            />
                        )
                    })}
                    <Box mt={1} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value.generateVoiceoverForNewQG}
                                color="primary"
                                onChange={(e: any, value) => {
                                    popupProps.validateClick(e, () => handleChangeCheckbox(value))
                                }}
                            />
                        }
                        label={
                            <Typography
                                sx={{
                                    color: 'rgba(9, 12, 16, 0.6)',
                                    fontSize: 14,
                                    fontWeight: 400
                                }}
                            >
                                Automatically generate voiceover
                            </Typography>
                        }
                    />

                    <UpgradePopup
                        anchorEl={contactUsAnchorEl}
                        options={upgradePopupSpeakers
                            .filter(speaker => speaker.isStudio)
                            .slice(0, 3)}
                        onClose={() => {
                            setContactUsAnchorEl(null)
                            setUpgradePopupSpeakers([])
                        }}
                    />
                </Box>
            }
            showActionCard={false}
        />
    )
}
