import {
    type ReactNode,
    type ChangeEvent,
    type CSSProperties,
    useState,
    memo,
    useCallback,
    useEffect,
    useMemo
} from 'react'

import { Box, CircularProgress } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { LimitationDialog } from 'UI/Components'

import { uuid, bytesToMB, getFirebaseUrl, uploadWithSignedUrl } from 'modules'
import { useBoolean } from 'hooks'

import { firebaseConfig } from 'env'

const limitImageResolution = (file: File, maxWidth?: number, maxHeight?: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        if (!maxWidth || !maxHeight) {
            resolve(file)
            return
        }

        const reader = new FileReader()

        reader.onload = async e => {
            const image = new Image()
            image.src = e.target!.result as string

            image.onload = async () => {
                let width = image.width
                let height = image.height

                // Check if the image dimensions exceed maxWidth or maxHeight
                if (width > maxWidth || height > maxHeight) {
                    const scaleRatio = Math.min(maxWidth / width, maxHeight / height)
                    width = Math.round(width * scaleRatio)
                    height = Math.round(height * scaleRatio)
                }

                // Create a canvas to resize the image
                const canvas = document.createElement('canvas')
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')
                ctx!.drawImage(image, 0, 0, width, height)

                // Convert the canvas to a Blob
                canvas.toBlob(async blob => {
                    blob ? resolve(blob) : reject('Error converting canvas to blob')
                }, file.type)
            }
        }

        reader.readAsDataURL(file)
    })
}

type Props = {
    labelComponent?: ReactNode
    iconColor?: string
    storagePath: string
    isTempStorage?: boolean
    filename?: string
    logoUrl?: string | null
    previewWidth?: number
    previewHeight?: number
    saveWidth?: number
    saveHeight?: number
    hideAttachFile?: boolean
    hideProgress?: boolean
    hidePreview?: boolean
    progressStyle?: CSSProperties
    previewStyle?: CSSProperties
    accept: string
    disabled?: boolean
    maxFileSizeInMb?: number
    onSizeLimit?: () => void
    onDone: (
        fileUrl: string,
        metadata: { name: string; size: number; type: string; fileId: string }
    ) => void
    onFileChange?: (file: File, fileId: string) => void
    onProgressChange?: (progress: number, fileId: string) => void
    onUploadStart?: (fileId: string) => void
}

export const FileUploader = memo(
    ({
        labelComponent,
        iconColor,
        storagePath,
        isTempStorage,
        logoUrl,
        filename,
        hideAttachFile,
        hidePreview,
        hideProgress,
        disabled = false,
        saveWidth,
        saveHeight,
        accept,
        previewStyle = {},
        progressStyle,
        previewWidth,
        previewHeight,
        maxFileSizeInMb,
        onSizeLimit,
        onDone,
        onFileChange,
        onProgressChange,
        onUploadStart
    }: Props) => {
        const sizeError = useBoolean()
        const setSizeError = sizeError.set

        const [preview, setPreview] = useState(logoUrl || null)
        const [progress, setProgress] = useState<null | number>(null)

        const fileId = useMemo(() => filename || uuid(), [filename])

        const uploadFileToStorage = useCallback(
            async (file: File) => {
                onUploadStart?.(fileId)

                const splitName = file.name?.split('.')
                const fileFormat = splitName?.pop() || 'png'

                const uploadFile = await limitImageResolution(file, saveWidth, saveHeight)

                const tempStoragePath = isTempStorage ? `${firebaseConfig.projectId}-temporary` : ''
                const bucketPrefix = 'gs://' + tempStoragePath
                const storageUrl = `${storagePath}/${fileId}.${fileFormat}`

                const res = await uploadWithSignedUrl(
                    bucketPrefix + '/' + storageUrl,
                    uploadFile,
                    progress => {
                        setProgress(progress)
                        onProgressChange?.(progress, fileId)
                    }
                )
                if (!res) return

                getFirebaseUrl(storageUrl, isTempStorage)
                    .then(url => {
                        return onDone?.(url, {
                            size: file.size,
                            type: file.type,
                            name: file.name,
                            fileId
                        })
                    })
                    .finally(() => setProgress(null))
            },
            [
                onUploadStart,
                fileId,
                saveWidth,
                saveHeight,
                isTempStorage,
                storagePath,
                onProgressChange,
                onDone
            ]
        )

        const onChange = useCallback(
            async (event: ChangeEvent<HTMLInputElement>) => {
                const selectedFile = event.target.files?.[0]
                if (!selectedFile) return

                setPreview(URL.createObjectURL(selectedFile))
                onFileChange?.(selectedFile, fileId)

                if (maxFileSizeInMb && maxFileSizeInMb < bytesToMB(Number(selectedFile?.size))) {
                    setSizeError(true)
                    onSizeLimit?.()
                    return
                }

                await uploadFileToStorage(selectedFile)
            },
            [onFileChange, fileId, maxFileSizeInMb, uploadFileToStorage, setSizeError, onSizeLimit]
        )

        useEffect(() => {
            if (typeof logoUrl === 'string') {
                setPreview(logoUrl)
            }
        }, [logoUrl])

        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center' }} data-test="file-uploader">
                    {preview && !hidePreview && (
                        <Box mr={1}>
                            <img
                                src={preview}
                                alt="logo"
                                style={{
                                    width: previewWidth ?? 200,
                                    ...(previewHeight && { height: previewHeight }),
                                    objectFit: 'cover',
                                    border: '1px solid #e0e0e0',
                                    ...previewStyle
                                }}
                            />
                        </Box>
                    )}

                    <label
                        style={{
                            width: '100%',
                            cursor: 'pointer',
                            margin: 0,
                            ...(disabled && { opacity: '0.5', cursor: 'default' })
                        }}
                    >
                        <Box display="flex" alignItems="center" position="relative">
                            {labelComponent}
                            {!hideAttachFile && (
                                <AttachFileIcon fontSize="small" style={{ color: iconColor }} />
                            )}

                            {typeof progress == 'number' && !hideProgress && (
                                <Box display="flex" style={progressStyle}>
                                    <CircularProgress
                                        size={32}
                                        variant="determinate"
                                        value={progress}
                                    />
                                </Box>
                            )}
                        </Box>

                        <input
                            id="file-uploader"
                            type="file"
                            onChange={onChange}
                            accept={accept}
                            style={{ display: 'none' }}
                        />
                    </label>
                </Box>
                <LimitationDialog
                    isOpen={sizeError.isTrue}
                    onClose={sizeError.setFalse}
                    title="File size alert"
                    text={<>The selected media file is too large (over {maxFileSizeInMb} MB).</>}
                />
            </>
        )
    }
)
