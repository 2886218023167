import { type ReactNode } from 'react'
import { Box, Breadcrumbs } from '@mui/material'

import { spacings, ThemeProvider, Typography, Button } from '@guidde/design-system'

type Props = {
    title: string
    subtitle: string
    mainActionButtons?: ReactNode
    breadCrumbs?: ReactNode
    breadCrumbsControls?: ReactNode
}

export const PageHeader = ({
    title,
    subtitle,
    mainActionButtons,
    breadCrumbsControls,
    breadCrumbs
}: Props) => (
    <ThemeProvider>
        <Box
            p={spacings['4xl']}
            borderBottom={theme => `1px solid ${theme.palette.grey[200]}`}
            bgcolor={theme => theme.palette.grey[25]}
        >
            {breadCrumbs && (
                <Box
                    mb={spacings['2xl']}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box mr={spacings['2xl']}>{breadCrumbs}</Box>
                    {breadCrumbsControls}
                </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box pr={mainActionButtons ? spacings['xl'] : 0}>
                    <Typography variant="heading" size="sm" fontWeight="semibold">
                        {title}
                    </Typography>
                    <Box mt={spacings['xs']} />
                    <Typography variant="text" size="sm" color="grey.600">
                        {subtitle}
                    </Typography>
                </Box>
                {mainActionButtons}
            </Box>
        </Box>
    </ThemeProvider>
)

PageHeader.BreadCrumbs = Breadcrumbs
PageHeader.Button = Button
