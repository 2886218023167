import { type ReactNode, useEffect, useState, memo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { Box, CircularProgress } from '@mui/material'

import { openUploadProgressPopup, setVideoProgress } from 'ducks/actions'

import { FileUploader } from 'UI/Components'
import { useAuth } from 'hooks'
import { logVideoImport } from 'modules'
import { useUploader, ALLOWED_VIDEO_FORMATS, getTitleFromFileName } from './use-uploader'
import { useUploadFileMaxLimit } from './use-upload-file-max-limit'

type Props = {
    labelComponent: ReactNode
    onUploadCallback?: (playbookId: string) => void
}

export const VideoUploader = memo(({ labelComponent, onUploadCallback }: Props) => {
    const dispatch = useDispatch()

    const archiveRef = useRef<any>({})

    const { uid } = useAuth()

    const { isPostProcessingLoading, handleUploadSuccess } = useUploader()

    const { maxUploadFileSizeInMb } = useUploadFileMaxLimit()

    const [progress, setProgress] = useState<{
        [key: string]: {
            progress: number
            name: string
        }
    }>({})

    const showCircularProgress =
        Object.values(progress).some(it => it.progress !== 100) || isPostProcessingLoading

    useEffect(() => {
        const filesObject = Object.entries(progress).reduce(
            (a, [id, item]) => ({
                ...a,
                [id]: {
                    id: id,
                    name: item.name,
                    progress: item.progress,
                    source: 'upload',
                    type: 'video'
                }
            }),
            {}
        )

        dispatch(openUploadProgressPopup(filesObject))
    }, [dispatch, progress])

    useEffect(() => {
        if (showCircularProgress) return
        setProgress({})
    }, [showCircularProgress])

    return (
        <label
            style={{
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
            }}
        >
            {labelComponent}
            {showCircularProgress && (
                <Box ml={1} display="flex">
                    <CircularProgress size={20} />
                </Box>
            )}

            <FileUploader
                hideAttachFile
                hidePreview
                hideProgress
                accept={ALLOWED_VIDEO_FORMATS}
                onFileChange={(file, fileId) => {
                    if (!file) return
                    archiveRef.current = {
                        ...archiveRef.current,
                        [fileId]: file.name
                    }
                }}
                maxFileSizeInMb={maxUploadFileSizeInMb}
                storagePath={`uploads/${uid}`}
                onUploadStart={onUploadCallback}
                onDone={async (url, metadata) => {
                    logVideoImport('localDrive', {
                        size: metadata.size,
                        type: metadata.type
                    })

                    await handleUploadSuccess(
                        metadata.fileId,
                        {
                            name: metadata.name,
                            type: metadata.type,
                            size: metadata.size
                        },
                        url
                    )

                    const title = getTitleFromFileName(metadata.name)

                    dispatch(
                        setVideoProgress({
                            playbookId: metadata.fileId,
                            fileName: title,
                            source: 'playbooks'
                        })
                    )
                }}
                onProgressChange={(value, fileId) => {
                    setProgress(prev => ({
                        ...prev,
                        [fileId]: {
                            progress: value,
                            name: archiveRef.current[fileId]
                        }
                    }))
                }}
            />
        </label>
    )
})
