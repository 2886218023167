export const firebaseConfig = {
    "projectId": "guidde-dev1",
    "appId": "1:46230017779:web:3871b206cc4cf4bd944986",
    "databaseURL": "https://guidde-dev1-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev1.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyB_FSBsRQYVTG3WPmUo76vXEn149tNAH9M",
    "authDomain": "dev1.guidde.com",
    "messagingSenderId": "46230017779",
    "environment": "guidde-dev1"
}

export const stripeKey =
'pk_test_51P1nHIJ59LzS85f7Sux1nHVR8c0Kaqy1pJT09V1CyrJz0qfGnRP8PGrKASs6QAbL1kAJN3H1c23C5xqWKmVQdN6P00jnGkF0gB'
export const stiggClientKey =
'client-63b1cadf-24ef-493e-bc20-92718f5d14b8:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
    productId: 'stage-app.guidde.com'
}

export const NEXT_PUBLIC_HOST_URL =
'https://dev1.guidde.com'
export const STIGG_CLIENT_KEY =
'client-63b1cadf-24ef-493e-bc20-92718f5d14b8:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'47910-93439a'