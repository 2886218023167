import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'

import { Button, ConfirmationDialog, Icon, IconButton, ThemeProvider } from '@guidde/design-system'

import { Box, Divider, Button as MuiButton } from '@mui/material'

import { useBoolean } from 'hooks'

import { AudioPlayer, SpacedGroup } from 'UI/Components'
import { AsyncSubtitlesContext } from 'UI/Routes/quick-guidde/AsyncSubtitlesProvider'
import { useStepSpeakingRate } from 'UI/Routes/quick-guidde/hooks'
import { QgTranscript } from './QgTranscript'
import { VoiceOverLoader } from './VoiceOverLoader'

import { removeMultipleAudioNotes, setAudioNote, setTempAudioNote } from 'ducks'
import { type SpeakerType } from 'app/types'
import VoiceoverAnalytics from 'analytics/voiceover'

export type VoiceOverOnEditData = {
    markdown: string
    speakingRate?: number
    applyToAllSteps?: boolean
}

type Props = {
    onEdit: (data: VoiceOverOnEditData) => void
    showSpeedButton?: boolean
}

export const VoiceOverPreview = ({ onEdit, showSpeedButton }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const showDeleteDialog = useBoolean()
    const showDeleteAllDialog = useBoolean()

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)

    const { processingStepsId } = useContext(AsyncSubtitlesContext)

    const { getStepSpeakingRate } = useStepSpeakingRate()

    const { audioNote, id } = steps?.[activeStep]

    const defaultSubtitles = useMemo(() => audioNote?.markdown || '', [audioNote?.markdown])

    const [value, setValue] = useState(defaultSubtitles)

    useEffect(() => {
        if (!audioNote) return
        setValue(audioNote.tempMarkdown || audioNote.markdown)
    }, [audioNote, defaultSubtitles])

    const cancelDeleteAllVoiceoverAction = () => {
        showDeleteAllDialog.setFalse()
    }

    const confirmDeleteAllVoiceoverAction = () => {
        showDeleteAllDialog.setFalse()

        const stepsIdsToRemoveVoiceover = steps.reduce((acc, speaker) => {
            if (speaker.audioNote?.type === 'textToSpeech') acc.add(speaker.id)

            return acc
        }, new Set<SpeakerType['id']>())

        dispatch(removeMultipleAudioNotes(stepsIdsToRemoveVoiceover))

        VoiceoverAnalytics.voiceoverT2VTabDeleteVoiceoverFromAllStepsClicked({ playbookId })
    }

    const cancelStepVoiceoverDeleteAction = () => {
        showDeleteDialog.setFalse()

        if (audioNote?.type === 'textToSpeech') {
            VoiceoverAnalytics.voiceoverT2VTabDeleteVoiceoverCancelBtnClicked({ playbookId })
        }
    }

    const confirmStepVoiceoverDeleteConfirmAction = () => {
        history.pushState({ selectedTab: audioNote?.type === 'speechToText' ? 0 : 1 }, '')
        // ts2 and s2t delete handling
        showDeleteDialog.setFalse()
        dispatch(setAudioNote())

        if (audioNote?.type === 'textToSpeech') {
            VoiceoverAnalytics.voiceoverT2VTabDeleteVoiceoverDeleteBtnClicked({ playbookId })
        }
    }

    const handleClickOnDeleteStepVoiceover = () => {
        showDeleteDialog.setTrue()

        if (audioNote?.type === 'textToSpeech') {
            VoiceoverAnalytics.voiceoverT2VTabDeleteVoiceoverDeleteBtnClicked({ playbookId })
        }
    }

    const handleClickRestartRecording = () => {
        const audioNoteIspeechToText = audioNote?.type === 'speechToText'
        if (!audioNoteIspeechToText) return

        dispatch(setAudioNote())
        history.pushState({ selectedTab: 0, startRecording: true }, '')
        VoiceoverAnalytics.voiceoverRestartFromPreview({ playbookId, activeStep })
    }

    const handleClickOnEditVoiceover = () => {
        const audioNoteIsTextToSpeech = audioNote?.type === 'textToSpeech'
        if (!audioNoteIsTextToSpeech) return
        const { speakerConfig, type, text, markdown } = audioNote

        dispatch(setAudioNote())
        dispatch(
            setTempAudioNote({
                type,
                text,
                markdown,
                speakerConfig,
                speakingRate: getStepSpeakingRate(steps[activeStep])
            })
        )

        VoiceoverAnalytics.voiceoverT2VTabEditVoiceoverBtnClicked({ playbookId })
    }

    const handleClickOnDeleteAllVoiceoverAction = () => {
        showDeleteAllDialog.setTrue()
    }

    if (!audioNote || audioNote.type === 'defaultSubtitles') return null

    return (
        <>
            <Box mb={3}>
                <AudioPlayer
                    audioFile={audioNote.audioUrl}
                    duration={Math.floor(audioNote.audioDuration)}
                    speakingRate={
                        showSpeedButton ? getStepSpeakingRate(steps[activeStep]) : undefined
                    }
                    speakerConfig={
                        audioNote.type === 'textToSpeech' ? audioNote.speakerConfig : undefined
                    }
                    onChangeSpeed={(speakingRate, applyToAllSteps) => {
                        onEdit({ markdown: value, speakingRate, applyToAllSteps })
                    }}
                    onPlay={() => {
                        VoiceoverAnalytics.voiceoverPlay({
                            voiceOverType: audioNote.type,
                            playbookId,
                            activeStep
                        })
                    }}
                    onTimePointerClick={timestamp => {
                        if (audioNote.type === 'textToSpeech') {
                            VoiceoverAnalytics.voiceoverT2VTabPlayerContainerPointerClicked({
                                playbookId,
                                timestamp
                            })
                        }
                    }}
                />
            </Box>
            <Box>
                <QgTranscript
                    text={audioNote?.text}
                    markdown={value}
                    language={
                        audioNote.type === 'speechToText'
                            ? audioNote.languageName
                            : audioNote.speakerConfig.langName
                    }
                    speakerConfig={
                        audioNote.type === 'textToSpeech' ? audioNote.speakerConfig : undefined
                    }
                    type={audioNote.type}
                    onChange={setValue}
                    onSave={markdown => {
                        onEdit({
                            markdown: markdown || value,
                            speakingRate: getStepSpeakingRate(steps[activeStep])
                        })
                    }}
                    onCancel={() => setValue(defaultSubtitles)}
                />

                {audioNote.type === 'speechToText' && processingStepsId.includes(id) && (
                    <VoiceOverLoader title="Creating transcript" />
                )}
            </Box>

            <ThemeProvider>
                <Box position="absolute" bottom={0} left={0} width="100%" textAlign="center">
                    <Divider />
                    <SpacedGroup spacing={2} justifyContent="center" py={2} px={4}>
                        {audioNote.type === 'speechToText' && (
                            <MuiButton
                                variant="outlined"
                                fullWidth
                                color="primary"
                                onClick={handleClickRestartRecording}
                            >
                                Restart Recording
                            </MuiButton>
                        )}

                        {audioNote.type === 'textToSpeech' && (
                            <Button
                                data-test="editor-edit-textToSpeech"
                                data-intercom="editor-edit-textToSpeech"
                                variant="outlined"
                                fullWidth
                                color="primary"
                                onClick={handleClickOnEditVoiceover}
                            >
                                Edit Voiceover
                            </Button>
                        )}
                        <IconButton
                            onClick={handleClickOnDeleteStepVoiceover}
                            label="Delete all"
                            variant="outlined"
                            color="secondary"
                            size="small"
                        >
                            <Icon icon={faTrashCan} size="1x" />
                        </IconButton>
                    </SpacedGroup>
                    {audioNote.type === 'textToSpeech' && (
                        <>
                            <Divider />
                            <Box py={1}>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                    onClick={handleClickOnDeleteAllVoiceoverAction}
                                >
                                    Remove voiceover from all steps
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </ThemeProvider>
            <ThemeProvider>
                <ConfirmationDialog
                    isOpen={showDeleteDialog.isTrue}
                    onCancel={cancelStepVoiceoverDeleteAction}
                    onConfirm={confirmStepVoiceoverDeleteConfirmAction}
                    title="Delete voiceover"
                    variant="error"
                    text="Are you sure you want to delete current voiceover?"
                    confirmText="Delete"
                    cancelText="Cancel"
                    icon={faTrashCan}
                />
                <ConfirmationDialog
                    isOpen={showDeleteAllDialog.isTrue}
                    onCancel={cancelDeleteAllVoiceoverAction}
                    onConfirm={confirmDeleteAllVoiceoverAction}
                    title="Delete voiceover from all steps"
                    variant="error"
                    text="Are you sure you want to delete the voiceover from all steps?"
                    confirmText="Delete"
                    cancelText="Cancel"
                    icon={faTrashCan}
                />
            </ThemeProvider>
        </>
    )
}
